import React from 'react'
import { Link } from 'gatsby'
import useDropdownMenu from 'react-accessible-dropdown-menu-hook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import * as styles from './NavItem.module.css'

const NavItem = (props) => {
  const { buttonProps, itemProps, isOpen } = useDropdownMenu(props.linkItems);

  return (
    <li className={styles.nav_list_item}>
      {props.dropdown ? (
        <>
          <button {...buttonProps} type="button">{props.children} <FontAwesomeIcon icon={isOpen ? faSortUp : faSortDown} /></button>
          <div className={isOpen ? styles.visible + ' ' + styles.menu : styles.menu} role='menu'>
            {props.linkItems.map(item => item.external ? (
              <a href={item.link} {...itemProps} key={item.link}>
                {item.name} <FontAwesomeIcon icon={faExternalLinkAlt} />
                <span className="visually-hidden">Directs to External Page</span>
              </a>
            ) : <Link to={item.link} {...itemProps} key={item.link}>{item.name}</Link>)}
          </div>
        </>
      ) : (<Link to={props.link}>{props.children}</Link>)}
    </li>
  )
}

export default NavItem