import React from 'react'
import { Helmet } from 'react-helmet'

import Navbar from './Navbar/Navbar'

import './Layout.css'

const Layout = (props) => {
  return (
    <>
      <Helmet defer={false}>
        <title>{`${props.title} | MS Votes`}</title>
        <meta name="description" content="Mississippi Votes is an organization of intergenerational synergy centering and led by young people invested in the progression of Mississippi." />
      </Helmet>
      <a href="#main" className="visually-hidden">Skip to Main Content</a>
      <Navbar />
      <main id="main">{props.children}</main>
    </>
  )
}

export default Layout