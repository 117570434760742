import React, { useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp, faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import Backdrop from '../../../UI/Backdrop/Backdrop'

import logo from '../../../../images/MS-Votes-logo-white.png'
import * as styles from './SideDrawer.module.css'

const SideDrawer = (props) => {
  const data = useStaticQuery(graphql`query SideDrawerQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            heading
            title
          }
        }
      }
    }
  }
  `)

  const whatWereAbout = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "What We're About").node.frontmatter
  const whoWeAre = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Who We Are").node.frontmatter
  const contact = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Contact").node.frontmatter
  const inTheNews = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "In the News").node.frontmatter
  const yce = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Youth Civic Engagement").node.frontmatter
  const msLeaders = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "MS Leaders").node.frontmatter
  const policyAndRes = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Policy and Research").node.frontmatter
  const usRep = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "US Representation").node.frontmatter
  const checkRegistration = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Check Your Registration").node.frontmatter
  // const pollingPlace = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Polling Place").node.frontmatter
  const registerToVote = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Register to Vote").node.frontmatter
  const pledgeToVote = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Pledge to Vote").node.frontmatter
  const employment = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Employment").node.frontmatter
  const fellowships = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Fellowships").node.frontmatter
  const volunteer = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Volunteer").node.frontmatter
  const blog = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Blog").node.frontmatter
  const rightsRestoration = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Rights Restoration").node.frontmatter
  const history = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "History").node.frontmatter
  const legTracker = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Legislative Tracker").node.frontmatter
  // const legGradebook = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Legislative Gradebook").node.frontmatter

  const [aboutMenuOpened, setAboutMenuOpened] = useState(false)
  const [edMenuOpened, setEdMenuOpened] = useState(false)
  const [opMenuOpened, setOpMenuOpened] = useState(false)
  const [psMenuOpened, setPsMenuOpened] = useState(false)
  const [voterResMenuOpened, setVoterResMenuOpened] = useState(false)

  return (
    <>
      <Backdrop clicked={props.close} show={props.opened} />
      <nav className={styles.mobile_nav + ' ' + (props.opened ? styles.open : styles.close)} >
        <Link to="/"><img src={logo} className={styles.logo} alt="MS Votes logo; redirects to home." /></Link>
        <button className={styles.close_btn} onClick={props.close}><FontAwesomeIcon icon={faTimes} /><span className="visually-hidden">Close Navigation</span></button>
        <ul>
          <li>
            <button onClick={() => {
              setAboutMenuOpened(!aboutMenuOpened)
              if (!aboutMenuOpened) {
                setOpMenuOpened(false)
                setEdMenuOpened(false)
                setVoterResMenuOpened(false)
                setPsMenuOpened(false)
              }
            }}>About <FontAwesomeIcon icon={aboutMenuOpened ? faSortUp : faSortDown} /></button>
            <ul hidden={!aboutMenuOpened}>
              <li><Link to="/about/what-were-about/">{whatWereAbout.heading}</Link></li>
              <li><Link to="/about/who-we-are/">{whoWeAre.heading}</Link></li>
              <li><Link to="/about/youth-civic-engagement/">{yce.heading}</Link></li>
              <li><Link to="/about/contact/">{contact.heading}</Link></li>
              <li><Link to="/about/in-the-news/">{inTheNews.heading}</Link></li>
            </ul>
          </li>
          <li>
            <button onClick={() => {
              setEdMenuOpened(!edMenuOpened)
              if (!edMenuOpened) {
                setAboutMenuOpened(false)
                setOpMenuOpened(false)
                setVoterResMenuOpened(false)
                setPsMenuOpened(false)
              }
            }}>Education <FontAwesomeIcon icon={edMenuOpened ? faSortUp : faSortDown} /></button>
            <ul hidden={!edMenuOpened}>
              <li><Link to="/education/ms-representation/">{msLeaders.heading}</Link></li>
              <li><Link to="/education/us-representation/">{usRep.heading}</Link></li>
            </ul>
          </li>
          <li><button onClick={() => {
            setVoterResMenuOpened(!voterResMenuOpened)
            if (!voterResMenuOpened) {
              setAboutMenuOpened(false)
              setOpMenuOpened(false)
              setEdMenuOpened(false)
              setPsMenuOpened(false)
            }
          }}>Voter Resources <FontAwesomeIcon icon={voterResMenuOpened ? faSortUp : faSortDown} /></button>
            <ul hidden={!voterResMenuOpened}>
              <li><Link to="/voter-resources/check-registration/">{checkRegistration.heading}</Link></li>
              {/* <li><Link to="/voter-resources/find-polling-place/">{pollingPlace.heading}</Link></li> */}
              <li><Link to="/voter-resources/pledge-to-vote/">{pledgeToVote.heading}</Link></li>
              <li><Link to="/voter-resources/register-to-vote/">{registerToVote.heading}</Link></li>
              <li><Link to="/voter-resources/toolkits/">Toolkits</Link></li>
            </ul>
          </li>
          <li><button onClick={() => {
            setPsMenuOpened(!psMenuOpened)
            if (!psMenuOpened) {
              setAboutMenuOpened(false)
              setEdMenuOpened(false)
              setVoterResMenuOpened(false)
              setOpMenuOpened(false)
            }
          }
          }>{policyAndRes.heading} <FontAwesomeIcon icon={psMenuOpened ? faSortUp : faSortDown} /></button>
            <ul hidden={!psMenuOpened}>
              <li><Link to="/policy-and-research/history/">{history.heading}</Link></li>
              <li><Link to="/policy-and-research/rights-restoration/">{rightsRestoration.heading}</Link></li>
              <li><Link to="/policy-and-research/legislative-tracker/">{legTracker.heading}</Link></li>
              {/* <li><Link to="/policy-and-research/legislative-gradebook/">{legGradebook.heading}</Link></li> */}
            </ul>
          </li>
          <li>
            <button onClick={() => {
              setOpMenuOpened(!opMenuOpened)
              if (!opMenuOpened) {
                setAboutMenuOpened(false)
                setEdMenuOpened(false)
                setVoterResMenuOpened(false)
                setPsMenuOpened(false)
              }
            }}>Opportunities <FontAwesomeIcon icon={opMenuOpened ? faSortUp : faSortDown} /></button>
            <ul hidden={!opMenuOpened}>
              <li><Link to="/opportunities/employment/">{employment.heading}</Link></li>
              <li><Link to="/opportunities/fellowships/">{fellowships.heading}</Link></li>
              <li>
                <a href="/opportunities/volunteer/">
                  {volunteer.heading} <FontAwesomeIcon icon={faExternalLinkAlt} />
                  <span className="visually-hidden">Directs to External Page</span>
                </a>
              </li>
            </ul>
          </li>
          <li><Link to="/blog/">{blog.heading}</Link></li>
          <li><Link to="/calendar/">Calendar</Link></li>
          <li style={{ paddingTop: '0.5rem' }}>
            <a href="https://donorbox.org/msvotesoperations" className={styles.donate_btn}>
              Donate <FontAwesomeIcon icon={faExternalLinkAlt} />
              <span className="visually-hidden">Directs to External Page</span>
            </a>
          </li>
        </ul>
      </nav>
    </>
  )
}

export default SideDrawer