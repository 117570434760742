import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import NavItem from './NavItem/NavItem'

import * as styles from './NavItems.module.css'

const NavItems = (props) => {
  const data = useStaticQuery(graphql`query NavItemsQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            heading
            title
          }
        }
      }
    }
  }
  `)

  const whatWereAbout = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "What We're About").node.frontmatter
  const whoWeAre = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Who We Are").node.frontmatter
  const contact = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Contact").node.frontmatter
  const inTheNews = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "In the News").node.frontmatter
  const yce = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Youth Civic Engagement").node.frontmatter
  const msLeaders = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "MS Leaders").node.frontmatter
  // const policyAndRes = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Policy and Research").node.frontmatter
  const history = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "History").node.frontmatter
  const usRep = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "US Representation").node.frontmatter
  // const checkRegistration = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Check Your Registration").node.frontmatter
  // const pollingPlace = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Polling Place").node.frontmatter
  // const registerToVote = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Register to Vote").node.frontmatter
  const pledgeToVote = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Pledge to Vote").node.frontmatter
  const employment = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Employment").node.frontmatter
  const fellowships = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Fellowships").node.frontmatter
  // const volunteer = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Volunteer").node.frontmatter
  const blog = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Blog").node.frontmatter
  const rightsRestoration = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Rights Restoration").node.frontmatter
  const legTracker = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Legislative Tracker").node.frontmatter
  // const legGradebook = data.allMarkdownRemark.edges.find(item => item.node.frontmatter.title === "Legislative Gradebook").node.frontmatter

  return (
    <nav>
      <ul className={styles.desktop_nav}>
        <NavItem dropdown linkItems={[{
          name: whatWereAbout.heading,
          link: '/about/what-were-about/',
          external: false
        }, {
          name: whoWeAre.heading,
          link: '/about/who-we-are/',
          external: false
        },
        {
          name: yce.heading,
          link: '/about/youth-civic-engagement/',
          external: false
        },
        {
          name: contact.heading,
          link: '/about/contact/',
          external: false
        },
        {
          name: inTheNews.heading,
          link: '/about/in-the-news/',
          external: false
        },]}>About</NavItem>
        <NavItem dropdown linkItems={[{
          name: msLeaders.heading,
          link: '/education/ms-representation/',
          external: false
        }, {
          name: usRep.heading,
          link: '/education/us-representation/',
          external: false
        }]}>Education</NavItem>
        <NavItem dropdown linkItems={[
          {
            name: pledgeToVote.heading,
            link: '/voter-resources/pledge-to-vote/',
            external: false
          }, {
            name: 'Toolkits',
            link: '/voter-resources/toolkits/',
            external: false
          }]}>Voter Resources</NavItem>
        <NavItem dropdown linkItems={[{
          name: history.heading,
          link: "/policy-and-research/history/",
          external: false
        }, {
          name: legTracker.heading,
          link: "/policy-and-research/legislative-tracker/",
          external: false
        }, {
          name: rightsRestoration.heading,
          link: "/policy-and-research/rights-restoration/",
          external: false
        }]}>Policy and Research</NavItem>
        <NavItem dropdown linkItems={[{
          name: employment.heading,
          link: '/opportunities/employment/',
          external: false
        }, {
          name: fellowships.heading,
          link: '/opportunities/fellowships/',
          external: false
        }]}>Opportunities</NavItem>
        <NavItem link="/blog/">{blog.heading}</NavItem>
        <NavItem link="/calendar/">Calendar</NavItem>
        <li>
          <a href="https://donorbox.org/msvotesoperations" className={styles.donate_btn}>
            Donate <FontAwesomeIcon icon={faExternalLinkAlt} />
            <span className="visually-hidden">Directs to External Page</span>
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default NavItems